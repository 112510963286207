

:root {

  /* Colors */
  --background-color: white;
  --secondary-background-color: #f5f5f7;

  --primary-text-color: #000000;
  --secondary-text-color: #1d1d1f;
  --tiertary-text-color:#6e6e73;

  /* Measurements */
  --max-width: 100%;
  --max-height: 100%; 

  /* Fonts */
  --primary-font-size: 70px;
  --primary-font-weight: 500;
  --secondary-font-weight: 600;

  /* Text */
  --gradient: linear-gradient(
    to left,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );

  --rw-words-color-one: #6b889d;
  --rw-words-color-two: #6b739d;
  --rw-words-color-three: #7a6b9d;
  --rw-words-color-four: #8d6b9d;
  --footer-a-color: #515154;
}


.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--secondary-background-color);
  line-height: 1.2;
  color: var(--secondary-text-color);
}

.header {
  background-color: var(--background-color);
}

.menu-container {
  overflow: hidden;
  display: inline-flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  padding: 20px 20px 20px;
  background-color: var(--background-color);
}

.logo-text {
  font-size: 25px;
  font-weight: var(--secondary-font-weight);
  margin-left: 10px;
  text-decoration: none;
  color: var(--primary-text-color);
}

.logo-text-mobile {
  font-size: 20px;
  font-weight: var(--secondary-font-weight);
  text-decoration: none;
  color: var(--primary-text-color);
}

.menu-item {
  text-decoration: none;
  font-weight: var(--secondary-font-weight);
  color: #79b3f4;
}

.section-header {
  z-index: 3;
}

#headline {
  padding: 20px 40px 40px;
  padding-bottom: 80px;
  background-color: var(--background-color);
}

#headline-mobile {
  z-index: 3;
  background-color: var(--background-color);
  padding: 8px 16px;
  max-width: var(--max-width);
  overflow-x: hidden;
  position: relative;
}

#headline-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#headline-container-mobile {
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.moving-graphics-container {
  left: 0;
  right: 0;
  overflow-x: "hidden";
  z-index: 1;
}

.moving-graphics-image {
  position: absolute;
  width: auto;
  height: 100px;
}

.moving-graphics-image-mobile {
  position: absolute;
  width: auto;
  height: 60px;
}

h1 {
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  color: #000000;
}

#headline-title-mobile {
  z-index: 3;
  font-size: 40px;
  width: var(--max-width);
  text-align: left;
}

.call-to-action-container {
  margin-top: 100px;
  flex-grow: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.call-to-action-container-mobile {
  z-index: 3;
  flex-grow: 1;
  margin: 80px auto;
  width: var(--max-width);
}

.call-to-action-left {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.call-to-action-left-mobile {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.request-button {
  height: 42px;
  background-color: transparent;
  border-radius: 8px;
  border-style: none;
}

.call-to-action-right {
  margin-left: 24px;
  padding: 3px;
  position: relative;
  background: var(--gradient);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}


.call-to-action-right-mobile {
  padding: 3px;
  position: relative;
  background: var(--gradient);
  border-radius: 8px;
  display:inline-block;
}

#call-to-action-right_text {
  color: var(--background-color);
  font-weight: var(--secondary-font-weight);
  font-size: 15px;
}


#call-to-action-right_text-mobile {
  color: var(--background-color);
  font-weight: var(--secondary-font-weight);
  font-size: 15px;
  float: left;
}

#play-text {
  text-decoration: none;
  color: var(--secondary-text-color);
  font-weight: var(--secondary-font-weight);
  margin-right: 4px;
}

#play-button {
  width: 20px;
  height: 20px;
}

.display-phone {
  background-color: var(--secondary-background-color);
  max-width: 500px;
  height: auto;
  background-attachment: fixed;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.display-phone-mobile {
  background-color: var(--secondary-background-color);
  width: var(--max-width);
  height: var(--max-height);
  background-attachment: fixed;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

.display-description-container {
  background-color: var(--secondary-background-color);
  padding: 60px 40px 20px;
  padding-right: 80px;
  position: "relative";
  float: "right";
  width: "50%";
  vertical-align: "middle";
}

.display-description-container-mobile {
  background-color: var(--secondary-background-color);
  position: relative;
  padding: 8px 16px 16px;
}


/* Description container */
.description-container {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.description-container-mobile {
  display: inline-flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
}


.description-container-image {
  margin-top: 20px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}


.description-container-image-mobile {
  margin-top: 10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  flex-grow: 1;
}

.display-description {
  font-weight: var(--secondary-font-weight);
  color: var(--tiertary-text-color);
  margin-bottom: 150px;
  font-size: 22px;
  width: 80%;
  align-self: center;
}

.display-description-mobile {
  z-index: 1;
  top: 0;
  font-weight: var(--secondary-font-weight);
  color: var(--tiertary-text-color);
  margin-bottom: 80px;
  font-size: 17px;
  width: var(--max-width);
  align-self: center;
}



/* USE TO */

.use-container {
  display: flex;
  margin: 0 auto;
}

.use-description-container {
  background-color: var(--background-color);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

/* TESTIMONIAL */

.testimonial-container {
  background-color: var(--background-color);
  border-radius: 8px;
  margin-bottom: 16px;
  display: inline-block;
  /* height: 300px; */
}

#testimonial-identity-container {
  display: flex;
  flex-direction: row;
}

#testimonial-identity-details-container {
  display: flex; 
  flex-direction: column;
}

/* FOUNDER MESSAGE */

.founder {
  position: relative;
  z-index: 3;
  margin-top: 200px;
  height: 600px;
  text-align: center;
  padding: 60px;
  overflow: hidden;
}

.founder-mobile {
  max-width: var(--max-width);
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
  z-index: 3;
  padding-top: 100px;
  height: 500px;
  text-align: center;
  padding: 16px;
  overflow: hidden;
}


#founder-title {
  position: relative;
  font-size: 40px;
  z-index: 1;
  font-weight: var(--primary-font-weight);
}

#founder-message {
  position: relative;
  font-size: 1.5em;
  font-weight: var(--primary-font-weight);;
  color: var(--tiertary-text-color);
  z-index: 1;
}

#founder-message-mobile {
  position: relative;
  font-size: 1em;
  font-weight: var(--primary-font-weight);
  color: var(--tiertary-text-color);
  margin-top: 16px;
}

#founder-button {
  width: 180px;
  padding: 3px;
  position: relative;
  background: var(--gradient);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
  z-index: 1;
}

/* 3D world */

#world {
  position: absolute;
  z-index: 0; 
  top: -100px;
  right:0px;
  overflow-x: hidden;
}

#world-mobile {
  position: absolute;
  z-index: 0; 
  top: -60px;
  left: 90px;
  overflow-x: hidden;
}

.rw-words {
  display: inline;
}

.rw-words-1 span {
  top: 4.8em;
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  position: absolute;
  opacity: 0;
  overflow: hidden;
  color: #6b969d;
  -webkit-animation: rotateWord 18s linear infinite 0s;
  -ms-animation: rotateWord 18s linear infinite 0s;
  animation: rotateWord 18s linear infinite 0s;
}

.rw-words-mobile {
  display: inline;
}

.rw-words-1-mobile span {
  position: absolute;
  top: 4.6em;
  width: var(--max-width);
  font-weight: var(--primary-font-weight);
  font-size: 40px;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  color: #6b969d;
  -webkit-animation: rotateWord 15s linear infinite 0s;
  -ms-animation: rotateWord 15s linear infinite 0s;
  animation: rotateWord 15s linear infinite 0s;
}

.rw-words span:nth-child(2) {
  animation-delay: 3s;
  color: var(--rw-words-color-one);
}
.rw-words span:nth-child(3) {
  animation-delay: 6s;
  color: var(--rw-words-color-two);
}
.rw-words span:nth-child(4) {
  animation-delay: 9s;
  color: var(--rw-words-color-three);
}
.rw-words span:nth-child(5) {
  animation-delay: 12s;
  color: var(--rw-words-color-four);
}

.rw-words-mobile span:nth-child(2) {
  animation-delay: 3s;
  color: var(--rw-words-color-one);
}
.rw-words-mobile span:nth-child(3) {
  animation-delay: 6s;
  color: var(--rw-words-color-two);
}
.rw-words-mobile span:nth-child(4) {
  animation-delay: 9s;
  color: var(--rw-words-color-three);
}
.rw-words-mobile span:nth-child(5) {
  animation-delay: 12s;
  color: var(--rw-words-color-four);
}

@keyframes rotateWord {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  17% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  20% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* HAMBURGER MENU */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 24px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: absolute;
  height: var(--max-height);
  border-radius: 20px;
}

/* General sidebar styles */
.bm-menu {
  background: var(--background-color);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: var(--max-height);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 1.8em;
  margin-bottom: 10px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent;
}

.footer {
  background-color:#e7e7e7;
  color: var(--footer-a-color);
  padding: 20px;
  padding-bottom: 60px;
}

.footer-navigation {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  font-weight: var(--secondary-font-weight);
  gap: 16;
  column-gap: 16;
}

.footer-navigation a {
  text-decoration: none;
  text-decoration-color: var(--footer-a-color);
  color: var(--footer-a-color);
  margin-top: 8px;
}
