#player-container {
    z-index: 5;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
}

#close {
    padding: 20px;
    width: 2.0em;
    height: 2.0em;
}

#close-mobile {
    padding: 20px;
    width: 1.5em;
    height: 1.5em;
}

.player {
    margin: 0 auto;
}