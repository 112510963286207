.request-container {
    height: 100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}


#request-content-container {
 padding: 30px;
}


#request-title {
    font-size: 25px;
    font-weight: 500;
}

#request-description{
    font-size: 20px;
    color: gray;
}

#request-input {
    width: 80%;
    height: 60px;
    border: none;
    border-bottom: 2px solid black;
    font-size:25px
}


#button-container {
    margin-top: 60px;
    width: 100%;
    padding-left: 60px,
}
.call-to-action-mobile {
    margin-left: 30px;
    width: 100px;
    background-color: #52cffe;
    border-radius: 8px;
}