.about {
    color: white;
}


.about-header {
    padding: 80px 20px 80px 20px;
    background: linear-gradient(to left bottom, #452ed2, #3c36d4, #333ed5, #2844d6, #1d4ad6, #025fe3, #0072ee, #0085f7, #00a8ff, #00c7ff, #00e2fc, #5ffbf1);
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 300px;
}

#about-header-title {
    font-size: 30px;
}

#about-header-description {
    font-size: 22px;
}